import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state:{
        apikeyToken:'',
        gsmc:'',
        mdmc:'',
        operatorid:'',
        mdbm:'',
        operatorname:''
    },
    mutations:{
        saveApi(state,apikey){
            state.apikeyToken = apikey
        },
        saveGsmc(state,gsmc){
            state.gsmc = gsmc
        },
        saveMdmc(state,mdmc){
            state.mdmc = mdmc
        },
        saveOperatorid(state,operatorid){
            state.operatorid = operatorid
        },
        saveMdbm(state,mdbm){
            state.mdbm = mdbm
        },
        saveOperatorname(state,operatorname){
            state.operatorname = operatorname
        }
    },
    actions:{
        saveApiAction({commit},apikey){
            commit('saveApi',apikey)
        },
        saveGsmcAction({commit},gsmc){
            commit('saveGsmc',gsmc)
        },
        saveMdmcAction({commit},mdmc){
            commit('saveMdmc',mdmc)
        },
        saveOperatoridAction({commit},operatorid){
            commit('saveOperatorid',operatorid)
        },
        saveMdbmAction({commit},mdbm){
            commit('saveMdbm',mdbm)
        },
        saveOperatornameAction({commit},operatorname){
            commit('saveOperatorname',operatorname)
        }
    }
})