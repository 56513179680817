import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import '@/components/css/public.css'
Vue.use(ElementUI).use(VXETable)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')